import React from 'react';
import Button from 'devextreme-react/button';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import DataGrid, {
  Column, Editing,
  Grouping, Form,
  GroupPanel, Pager, Popup,
  Paging, Scrolling, TotalItem, Summary
} from 'devextreme-react/data-grid';
import CustomStore from "devextreme/data/custom_store";
import { Workbook } from 'exceljs';
import 'devextreme/data/odata/store';
import NavBar from "./NavBar.js";

// const URL_API = "http://localhost:5000";
const URL_API = "https://mlktrans-yw61.onrender.com"
const tabData = localStorage.profitabilityData && JSON.parse(localStorage.profitabilityData).rows ? JSON.parse(localStorage.profitabilityData).rows : [];
const POPUP_width = "80%";
const POPUP_height = "80%";
class Profitability extends React.Component {
  constructor(props) {
    super(props);
    this.state = { gridElements: [] };
    console.log(tabData)
    for (let i = 0; i < tabData.length; i++) {
      this["itemGridRef" + i] = React.createRef();
      let TABLE_NAME = "profitability_car_data";
      let RELATED_TABLE_NAME = "profitability_car";
      let gridData = new CustomStore({
        key: TABLE_NAME + "id",
        load: () =>
          this.sendRequest(URL_API + "/listItems", "POST", {
            TableName: TABLE_NAME,
            RelatedKey: tabData[i].profitability_carid,
            RelatedKeyTable: RELATED_TABLE_NAME,

          }),
        insert: (values) => {
          console.log(values)
          values.profitability_carid = window.profitability_carid;

          if (values.pricenet) {
            let netPrice = Number(values.pricenet);
            let currency = values.currency;
            let rate = values.eurorate;
            values.priceron = netPrice * rate;
          }
          if (values.km && values.priceron)
            values.priceperkm = Number(values.priceron) / Number(values.km);

          values.invoicevalue = Number(values.priceron) * (1 + Number(values.tvapercent) / 100);
          values.tvavalue = Number(values.priceron) * Number(values.tvapercent) / 100;

          values.rentability = 0.84 * (values.priceron - (Number(values.rca) + Number(values.cmr) + Number(values.salary)
            + Number(values.vignette) + Number(values.administration) + Number(values.accountancy) + Number(values.repairs) + Number(values.roadtax)
            + Number(values.leasing) + Number(values.semitrailcosts) + Number(values.fueladblue) + Number(values.fuel)));
          values.tax = 0.16 * values.rentability;

          this.sendRequest(URL_API + "/insert", "POST", {
            TableName: TABLE_NAME,
            Values: JSON.stringify(values),
          })
        }
        ,
        update: (key, values) =>{
          let savedData = window.data ;
          let fields = ["pricenet","priceron", "currency" , "eurorate", "km", "tvapercent", "rca","accountancy",
        "fuel","fueladblue","semitrailcosts","leasing","roadtax","repairs","administration","vignette","salary","cmr" ];
          for(var i = 0; i< fields.length; i++){
            if(!values[fields[i]]){
              values[fields[i]] = savedData[fields[i]];
            }
          }
          if (values.pricenet) {
            let netPrice = Number(values.pricenet);
            let currency = values.currency;
            let rate = values.eurorate;
            values.priceron = netPrice * rate;
          }
          if (values.km && values.priceron)
            values.priceperkm = Number(values.priceron) / Number(values.km);

          values.invoicevalue = Number(values.priceron) * (1 + Number(values.tvapercent) / 100);
          values.tvavalue = Number(values.priceron) * Number(values.tvapercent) / 100;

          values.rentability = 0.84 * (values.priceron - (Number(values.rca) + Number(values.cmr) + Number(values.salary)
            + Number(values.vignette) + Number(values.administration) +  Number(values.accountancy) +Number(values.repairs) 
            + Number(values.roadtax)
            + Number(values.leasing) + Number(values.semitrailcosts) + Number(values.fueladblue) + Number(values.fuel)));
          values.tax = 0.16 * values.rentability;

          this.sendRequest(URL_API + "/update", "POST", {
            id: key,
            TableName: TABLE_NAME,
            Values: JSON.stringify(values),
          })
          window.location.reload();
        },
        remove: (key) =>
          this.sendRequest(URL_API + "/delete", "POST", {
            TableName: TABLE_NAME,
            id: key,
          }),
      });
      let element = <Item title={tabData[i].platenumber + " " + tabData[i].model} key={"itemGrid" + i}>
        <DataGrid
          dataSource={gridData}
          allowInsert={true}
          keyExpr="ID"
          showBorders={true}
          id={"itemGrid" + i}
          ref={this["itemGridRef" + i]}
          columnWidth={100}
          onEditorPreparing={this.onEditorPreparing}
          onInitNewRow={this.onInitNewRow}
          onEditingStart={this.onEditingStart}
        >
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={true} />
          <Pager
            allowedPageSizes={[10,20,30,40,50,60]}
            showPageSizeSelector={false}
          />
          <Paging defaultPageSize={5} />
          <Editing
            mode='popup'
            allowAdding={true}
            allowDeleting={true}
            allowUpdating={true}
            confirmDelete={false}
            useIcons={true}
          >
            <Popup title="Adauga inregistrare" showTitle={true} width={POPUP_width} height={POPUP_height} />

            <Form >
              <Item itemType="group" colCount={1} colSpan={2}>
                <Item dataField="date" />
              </Item>

              <Item itemType="group" colCount={4} colSpan={2}>
                <Item dataField="driver" />
                <Item dataField="salary" />
                <Item dataField="startingpoint" />
                <Item dataField="destination" />

                <Item dataField="mass" />
                <Item dataField="consumption" />
                <Item dataField="routes" />
                <Item dataField="hubroutes" />

                <Item dataField="emagtransfer" />
                <Item dataField="hubtransfer" />
                <Item dataField="km" />
                {/* <Item dataField="" /> */}
              </Item>
              <Item itemType="group" caption="Alimentari" colCount={4} colSpan={2}>
                <Item dataField="fuelL" />
                <Item dataField="fuel" />
                <Item dataField="fueladblueL" />
                <Item dataField="fueladblue" />
              </Item>
              <Item itemType="group" caption="Semiremorca" colCount={4} colSpan={2}>
                <Item dataField="semitrail" />
                <Item dataField="semitrailcosts" />
                <Item dataField="semitraildetails" />
              </Item>
              <Item itemType="group" caption="Pret" colCount={4} colSpan={2}>
                <Item dataField="pricenet" />
                <Item dataField="currency" />
                <Item dataField="eurorate" />
                {/* <Item dataField="priceron"/> */}
              </Item>
              <Item itemType="group" caption="Factura" colCount={4} colSpan={2}>
                <Item dataField="tvapercent" />
                {/* <Item dataField="invoicevalue" /> */}
                {/* <Item dataField="tvavalue"  /> */}
                {/* <Item dataField="priceperkm" /> */}
              </Item>
              <Item itemType="group" caption="  " colCount={4} colSpan={2}>
                <Item dataField="vignette" />
                <Item dataField="rca" />
                <Item dataField="cmr" />
                <Item dataField="administration" />
                <Item dataField="accountancy" />
                <Item dataField="repairs" />
                <Item dataField="roadtax" />
                <Item dataField="leasing" />
                {/* <Item dataField="tax" /> */}
                {/* <Item dataField="rentability" /> */}
              </Item>
              <Item dataField="profitability_carid" />
            </Form>
          </Editing>
          <Column dataField="driver" caption="Sofer" groupIndex={0} />
          <Column dataField="profitability_carid" caption="Legatura" value={tabData[i].profitability_carid} visible={false} />
          <Column dataField="date" caption="Data" dataType="date" editorOptions={{ onValueChanged: this.onValueChanged }} />
          <Column dataField="startingpoint" caption="Plecare" />
          <Column dataField="destination" caption="Destinatie" />
          <Column dataField="mass" caption="Tonaj" dataType="numeric" />
          <Column dataField="consumption" caption="Consum" dataType="numeric" />
          <Column dataField="routes" caption="Curse" dataType="numeric" />
          <Column dataField="hubroutes" caption="Curse HUB" dataType="numeric" />
          <Column dataField="emagtransfer" caption="Transfer EMAG" dataType="numeric" />
          <Column dataField="hubtransfer" caption="Transfer HUB" dataType="numeric" />
          <Column dataField="km" caption="km" dataType="numeric" />
          <Column caption="Pret">
            <Column dataField="pricenet" caption="Pret NET" dataType="numeric" />
            <Column dataField="currency" caption="Moneda" />
            <Column dataField="eurorate" caption="Curs EURO" dataType="numeric" />
            <Column dataField="priceron" caption="Pret total RON" dataType="numeric" />
          </Column>
          <Column caption="Factura">
            <Column dataField="tvapercent" caption="TVA(%)" dataType="numeric" />
            <Column dataField="invoicevalue" caption="Total factura" dataType="numeric" />
            <Column dataField="tvavalue" caption="Valoare TVA" dataType="numeric" />
          </Column>
          <Column dataField="priceperkm" caption="Pret/km" dataType="numeric" />
          <Column dataField="client" caption="client" />
          <Column dataField="salary" caption="Salariu" dataType="numeric" />
          <Column dataField="vignette" caption="Rovinieta" dataType="numeric" />
          <Column dataField="rca" caption="rca" dataType="numeric" />
          <Column dataField="cmr" caption="cmr" dataType="numeric" />
          <Column caption="Alimentari">
            <Column dataField="fuelL" caption="Alimentari Diesel Litrii" dataType="numeric" />
            <Column dataField="fuel" caption="Alimentari Diesel Valoare" dataType="numeric" />
            <Column dataField="fueladblueL" caption="Alimentari AdBlue Litrii" dataType="numeric" />
            <Column dataField="fueladblue" caption="Alimentari AdBlue Valoare" dataType="numeric" />
          </Column>
          <Column dataField="administration" caption="Administrative" dataType="numeric" />
          <Column dataField="accountancy" caption="Contabilitate" dataType="numeric" />
          <Column dataField="repairs" caption="Reparatii" dataType="numeric" />
          <Column dataField="roadtax" caption="taxe drum" dataType="numeric" />
          <Column dataField="leasing" caption="leasing Camion" dataType="numeric" />
          <Column caption="Remorca">
            <Column dataField="semitrail" caption="Nr inmatriculare" />
            <Column dataField="semitrailcosts" caption="Cheltuieli" dataType="numeric" />
            <Column dataField="semitraildetails" caption="Detalii" />
          </Column>
          <Column dataField="tax" caption="Impozit" dataType="numeric" />
          <Column dataField="rentability" caption="Rentabilitate" dataType="numeric" />
          {/**Aici din totalul la “Pret” sa scada totalul la “ Salariu+Rovinieta+rca+Asigurare cmr+
           * Alimentari diesel+Adblue+Administrative+Reparatii+taxe drum+leasing camion+Semiremorca” 
           * iar din suma rezultata sa scada 16%(Impozitul) si sa imi afiseze profitul masinii */}

          <Scrolling columnRenderingMode="virtual" />
          <Summary recalculateWhileEditing={true}>
            <TotalItem
              column="km"
              summaryType="sum" />
            <TotalItem
              column="rentability"
              summaryType="sum" />
          </Summary>
        </DataGrid>
      </Item>
      this.state.gridElements.push({ element: element, index: i, data: tabData[i] });
    }
    this.priceGridRef = React.createRef();
    this.ratingGridRef = React.createRef();
  }
  onEditingStart(data){
    window.data = data.data;
  }
  onValueChanged(cell, data) {
    // debugger
    // window.currentComponent.cellValue(0, "driver", "Marinica");
    // window.currentComponent.cellValue(0, data.component._label._props.text, data.value);
    // this.dataGrid.cellValue(editRowIndex, "EmployeeName");
    // e.editorOptions.disabled = e.row.data && e.row.data.FirstName === "";
  }
  onInitNewRow(data) {
    data.data.eurorate = 1;
    data.data.tvapercent = 19;
    data.data.eurorate = 1;
    data.data.currency = "RON";
    data.data.rca = 0;
    data.data.cmr = 0;
    data.data.salary = 0;
    data.data.vignette = 0;
    data.data.administration = 0;
    data.data.accountancy = 0;
    data.data.repairs = 0;
    data.data.roadtax = 0;
    data.data.leasing = 0;
    data.data.semitrailcosts = 0;
    data.data.fueladblue = 0;
    data.data.fuel = 0;
  }
  onEditorPreparing(data) {
    // console.log(data)
    if (data.dataField === "profitability_carid") {
      window.profitability_carid = data.editorOptions.value;
      window.currentComponent = data.component;
    }

    // window[data.dataField] = data.editorOptions.value

    // if (!data || !data.row || !data.row.key) {
    //   // this.setState({ RELATED_KEY: this.state.RELATED_KEY });
    // } else {
    //   if (this.state.RELATED_KEY !== data.row.key) {
    //     // this.setState({ RELATED_KEY: data.row.key });
    //   }

    //   window.RELATED_KEY = data.row.key;
    // }

    if (!data || !data.row || !data.row.isNewRow) {
      window.MODE = "EDIT";
      window.CURRENT_DATA = data;
    } else {
      window.MODE = "INSERT";
    }
  }

  sendRequest(url, method = "GET", data = {}) {
    if (method === "GET") {
      return fetch(url, {
        method,
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) return json.data;
          throw json.Message;
        })
      );
    }

    const params = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");
    return fetch(url, {
      method,
      body: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then(function (text) {
          let res = JSON.parse(text);
          return {
            data: res.recordset,
            totalCount: res.totalCount,
            summary: res.summary,
            groupCount: res.groupCount,
          };
        });
      }
      return result.json().then((json) => {
        throw json.Message;
      });
    });
  }

  render() {
    var tabLists = this.state.gridElements.map(x => x.element);
    return (

      <div>
        <NavBar />
        {/* <div className="Title">Profitabilitate</div> */}
        <div className='ContainerTabPanel'>
          <div id="exportContainer">
            <Button
              text="Export excel"
              icon="xlsxfile"
              onClick={this.exportGrids}
            />
          </div>
          <TabPanel id="tabPanel" deferRendering={false}>
            {tabLists}
          </TabPanel>
        </div>
      </div>
    );
  }

  exportGrids = () => {
    const context = this;

    const workbook = new Workbook();
    let excelSheets = []
    let execute = "";
    // let exportDataGrid = exportDataGrid;
    for (var i = 0; i < context.state.gridElements.length; i++) {
      var item = context.state.gridElements[i].data;
      var sheet = workbook.addWorksheet(item.platenumber + " " + item.model);
      sheet.getRow(2).getCell(2).value = item.platenumber + " " + item.model;
      sheet.getRow(2).getCell(2).font = { bold: true, size: 16, underline: 'double' };
      excelSheets.push(sheet);
      if (i === 0) {
        execute = execute + "DevExpress.excelExporter.exportDataGrid({"
          + "worksheet: excelSheets[0],"
          + "component: context.itemGridRef0.current.instance,"
          + "topLeftCell: { row: 4, column: 2 },"
          + "})"
      } else {
        debugger
        window['instance_itemGridRef' + i] = context['itemGridRef' + i].current.instance;
        execute = execute + ".then(() =>   DevExpress.excelExporter.exportDataGrid({"
          + "  worksheet: excelSheets[" + i + "],"
          + "    component: window['instance_itemGridRef" + i + "'],"
          + "    topLeftCell: { row: 4, column: 2 },"
          + "  })"
          + ")"
      }
    }
    if (execute !== "") {
      console.log(tabData)
      let date = tabData[0].createdOn[0];
      let year = date.substring(0,4);
      let month = date.substring(5,7);
      execute = execute + ".then(() => {"
        + "workbook.xlsx.writeBuffer().then((buffer) => {"
        + "  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'RaportProfitabilitate_"+ month+ "_" +year+ ".xlsx');"
        + "});"
        + "});"
      console.log(execute);
      eval(execute);
    }
  }

  setAlternatingRowsBackground(gridCell, excelCell) {
    if (gridCell.rowType === 'header' || gridCell.rowType === 'data') {
      if (excelCell.fullAddress.row % 2 === 0) {
        excelCell.fill = {
          type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' }, bgColor: { argb: 'D3D3D3' },
        };
      }
    }
  }

}

export default Profitability;
