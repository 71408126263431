import React from "react";
import NavBar from "./NavBar.js";
import Grid from "./Grid.js";
import "./Cars.css";

class Cars extends React.Component {
  render() {
    return (
      <>
        <NavBar />
        <Grid tableName="car" />
        <div className="Title">Flota</div>
      </>
    );
  }
}

export default Cars;
