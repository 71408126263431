import React from 'react';

// const API_URL = "http://localhost:5000/upload";
const API_URL = "https://mlktrans-yw61.onrender.com"
class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            fileName: ""
        }
        this.uploadFile = this.uploadFile.bind(this);
        this.saveFile = this.saveFile;
    }

    saveFile = (e) => {
        window.FileUpload = { file: e.target.files[0], fileName: e.target.files[0].name };
        this.setState({ file: e.target.files[0], fileName: e.target.files[0].name });
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        try {
            fetch(API_URL, {
                method: 'POST',
                body: formData
               
            }).then(response => response.json())
            .then(data => {
                debugger
                if(data.length > 0){
                    window.FileName = data[0].filename;
                    window.OriginalFileName = data[0].originalname;
                }
                
              console.log(data)
            }).catch((err) => ("Error occured", err));
        } catch (ex) {
            console.log(ex);
        }
    };

    uploadFile = async (e) => {
        const formData = new FormData();
        formData.append("file", window.FileUpload.file);
        try {
            fetch(API_URL, {
                method: 'POST',
                body: formData
               
            }).then((res) => console.log(res))
                .catch((err) => ("Error occured", err));
        } catch (ex) {
            console.log(ex);
        }
    };

    render() {
        return (
            <div className="App">
                <input type="file" onChange={this.saveFile} />
                {/* <button onClick={this.uploadFile}>Upload</button> */}
            </div>
        );
    }
}

export default FileUpload;