import React from "react";
import NavBar from "./NavBar.js";
import Grid from "./Grid.js";
import "whatwg-fetch";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <NavBar />
        <div className="Title">Comenzi transport</div>
        <Grid tableName="transportorder" />
        {/* <div class="Title secondTitle">Rapoarte profitabilitate</div> */}
        <Grid tableName="profitability" />
        
      </>
    );
  }
}

export default Reports;
