import React from 'react';
import NavBar from "./NavBar.js";
import Grid from './Grid.js';
import 'whatwg-fetch';


class Employees extends React.Component {
    render() {        
        return (
            <>
            <NavBar />
            <Grid  tableName = "employee"/>
            <div className="Title">Angajati</div>
            </>
        );
    }
}

export default Employees;
