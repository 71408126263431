import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
function Login() {
  const navigate = useNavigate();

  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const errors = {
    userName: "invalid credentials",
    pass: "invalid credentials",
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { userName, pass } = document.forms[0];

    // Compare user info
    if (isValidLogin({ userName, pass })) {
      localStorage.setItem("authenticated", true);
      navigate("/Notifications");
    } else {
      // Username not found
      setErrorMessages({ name: "userName", message: errors.userName });
    }
  };

  const isValidLogin = (userData) => {
    debugger
    if(userData && userData.userName && userData.userName.value === "admin" &&
    userData.pass && userData.pass.value === "MLKTrans2023"){
      return true;
    }else{
      return false;
    }
    
  };
  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Username </label>
          <input type="text" name="userName" required />
          {renderErrorMessage("userName")}
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" required />
          {renderErrorMessage("pass")}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <div className="app">
      <div className="login-form">
        <div className="title">Sign In</div>
        {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
      </div>
    </div>
  );
}

export default Login;
