import React from "react";
import { useNavigate } from "react-router-dom";
import "./Grid.css";
import {
  DataGrid,
  Sorting,
  Column,
  Editing,
  Item,
  Toolbar,
  FilterRow,
  RequiredRule,
  Form,
  Popup,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import download from "js-file-download";
import FileUpload from "./FileUpload.js";

// const URL_API = "http://localhost:5000";
const URL_API = "https://mlktrans-yw61.onrender.com"
const pageSizes = [ "all"];
const POPUP_width = "80%";
const POPUP_height = "80%";

function getDateDiffInAge(date2) {
  let date1 = new Date();
  var diffMonth =(date1.getTime() - date2.getTime()) / 1000;
       diffMonth /= (60 * 60 * 24 * 7 * 4);
       diffMonth = Math.floor(diffMonth);

  var years = Math.floor(diffMonth / 12);
  var months = diffMonth - years * 12;
    var message = "";
    message += years + " ani"
    message += " si " + months + " luni "
    

  return message;
}

async function downloadDocument(){
  console.log(window.data)
  if(!window.data.FileName){
    alert("Aceasta inregistrare nu are un document incarcat!")
    return;
  }
  const existingPdfBytes = await fetch(
    URL_API + "/getfile?fileName=" + window.data.FileName
  ).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pdfBytes = await pdfDoc.save();
  download(pdfBytes, window.data.OriginalFileName);
}

async function createPdf() {
  const existingPdfBytes = await fetch(
    URL_API + "/getfile?fileName=comandatransportMLK.pdf"
  ).then((res) => res.arrayBuffer());
  console.log(existingPdfBytes);
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();
  const formData = window.CURRENT_DATA.row.data;
  firstPage.drawText(formData.number.toString(), {
    x: width - 165,
    y: height - 129,
    size: 15,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.fromdate.toString(), {
    x: width - 195,
    y: height - 152,
    size: 15,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.currency.toString(), {
    x: width - 150,
    y: height - 251,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.currency.toString(), {
    x: width - 196,
    y: height - 337,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.total.toString(), {
    x: width - 140,
    y: height - 380,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.total.toString(), {
    x: width - 140,
    y: height - 394,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.km.toString(), {
    x: width - 280,
    y: height - 380,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.noofroutes.toString(), {
    x: width - 345,
    y: height - 380,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.requestedservice.toString(), {
    x: width - 545,
    y: height - 380,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.supplierdata.toString(), {
    x: width - 559,
    y: height - 143,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.mass.toString(), {
    x: width - 400,
    y: height - 380,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(formData.paymentdetails.toString(), {
    x: width - 185,
    y: height - 300,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  const pdfBytes = await pdfDoc.save();
  download(pdfBytes, "Comandatransport.pdf");
}



class Grid extends React.Component {
  constructor(props) {
    super(props);
    const TABLE_NAME = this.props.tableName;
    const RELATED_KEY_TABLE = this.props.selectedKeyTable;
    var RELATED_KEY = this.props.selectedKey;

    this.state = {
      TABLE_NAME: TABLE_NAME,
      RELATED_KEY: RELATED_KEY,
      gridData: new CustomStore({
        key: TABLE_NAME + "id",
        load: () =>
          this.sendRequest(URL_API + "/listItems", "POST", {
            TableName: TABLE_NAME,
            RelatedKey: window.RELATED_KEY,
            RelatedKeyTable: RELATED_KEY_TABLE,

          }),
        insert: (values) => {
          
          if (TABLE_NAME === "document" || TABLE_NAME === "cardocument") {
            if (window.FileName) {
              values.filename = window.FileName;
              values.originalfilename = window.OriginalFileName
            }
            if(window.parent.carid){
              values.carId = window.parent.carid ;
            }
          }
          this.sendRequest(URL_API + "/insert", "POST", {
            TableName: TABLE_NAME,
            Values: JSON.stringify(values),
          })
        }
        ,
        update: (key, values) => {
          if (TABLE_NAME === "document" || TABLE_NAME === "cardocument") {
            if (window.FileName) {
              values.FileName = window.FileName;
              values.OriginalFileName = window.OriginalFileName
            }
          }
          this.sendRequest(URL_API + "/update", "POST", {
            id: key,
            TableName: TABLE_NAME,
            Values: JSON.stringify(values),
          })
        },
        remove: (key) =>
          this.sendRequest(URL_API + "/delete", "POST", {
            TableName: TABLE_NAME,
            id: key,
          }),
      }),
      requests: [],
      refreshMode: "reshape",
    };
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onEditorPrepared = this.onEditorPreparing.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this)

  }
  
  editProfitability(e) {
    let profitabilityId = e.data.profitabilityid;
    window.profitabilityId = profitabilityId;
    localStorage.setItem("profitabilityId", profitabilityId);
    console.log(window.profitabilityId)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        TableName: "profitability_car",
        RelatedKey: window.profitabilityId,
        RelatedKeyTable: "profitability",
        ExtraTable: "car"
      })
    };
    fetch(URL_API + "/listItems", requestOptions)
      .then(response => response.json())
      .then(data => {
        window.profitabilityData = data;
        localStorage.setItem("profitabilityData", JSON.stringify(data));
        // useNavigate("/profitability");
        document.getElementById("profitabilitySection").click();
        // window.location.pathname = '/profitability';
      });







  }

  addProfitability(e) {
    const data = {
      TableName: "Profitability",
      Values: JSON.stringify({ "description": "Raport profitabilitate" }),
    };
    const params = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");
    return fetch(URL_API + "/insert", {
      method: "POST",
      body: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }).then((result) => {
      console.log(result)
      if (result.ok) {
        return result.text().then(function (text) {
          let res = JSON.parse(text);
          document.getElementById("reportsSection").click();
          // window.location.reload();
          return {
            data: res.recordset,
            totalCount: res.totalCount,
            summary: res.summary,
            groupCount: res.groupCount,
          };
        });
      }
      return result.json().then((json) => {
        throw json.Message;
      });
    });

  }

  onEditingStart(data){
    
    window.data = data.data;
    if(data.data.carid){
      window.parent = data.data;
    }
  }

  onEditorPreparing(data) {
    if (!data || !data.row || !data.row.key) {
      // this.setState({ RELATED_KEY: this.state.RELATED_KEY });
    } else {
      if (this.state.RELATED_KEY !== data.row.key) {
        this.setState({ RELATED_KEY: data.row.key });
      }

      window.RELATED_KEY = data.row.key;
    }
    

    if (!data || !data.row || !data.row.isNewRow) {
      window.MODE = "EDIT";
      window.CURRENT_DATA = data;
    } else {
      window.MODE = "INSERT";
    }
  }

  sendRequest(url, method = "GET", data = {}) {
    if (method === "GET") {
      return fetch(url, {
        method,
      }).then((result) =>
        result.json().then((json) => {
            console.log(json)
            
          if (result.ok) return json.data;
          throw json.Message;
        })
      );
    }

    const params = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");
    return fetch(url, {
      method,
      body: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then(function (text) {
          let res = JSON.parse(text);
          return {
            data: res.recordset,
            totalCount: res.totalCount,
            summary: res.summary,
            groupCount: res.groupCount,
          };
        });
      }
      return result.json().then((json) => {
        throw json.Message;
      });
    });
  }


  render() {
    var { gridData } = this.state;
    
    let carDocumentGrid = <></>
    if ( window.MODE === "EDIT" && this.state.TABLE_NAME === "car") {
      carDocumentGrid = <Grid
      tableName="cardocument"
      selectedKey={window.RELATED_KEY}
      selectedKeyTable="car"
    />;
    }
    let downloadFileButton = <></>
    if ( this.state.TABLE_NAME === "document" || this.state.TABLE_NAME === "cardocument") {
      downloadFileButton = <button onClick={downloadDocument}>Descarca document</button>;
    }
    let generateReportButton = <></>;
    if (window.MODE === "EDIT" && this.state.TABLE_NAME === "transportorder") {
      generateReportButton = <button onClick={createPdf}>Create PDF</button>;
    }
    let ageInWork = <></>;
    if (
      this.state.TABLE_NAME === "employee" &&
      window.MODE === "EDIT" &&
      window.CURRENT_DATA &&
      window.CURRENT_DATA.row &&
      window.CURRENT_DATA.row.data &&
      window.CURRENT_DATA.row.data.employmentdate
    ) {
      ageInWork = (
        <div>
          Vechime la locul de munca:
          {getDateDiffInAge(
            new Date(window.CURRENT_DATA.row.data.employmentdate)
          )}
        </div>
      );
    }


    if (this.state.TABLE_NAME === "employee")
      return (
        <>
          <React.Fragment>
            <div className="gridContainer">
              <DataGrid
                id="gridContainer"
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={true}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                showRowLines={true}
                showColumnLines={true}
                onEditorPreparing={this.onEditorPreparing}
              // onEditorPrepared={this.onEditorPreparing}
              // onEditingStart={this.onEditorPreparing}
              >
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  confirmDelete={true}
                  useIcons={true}
                >
                  <Popup
                    title="Informatii angajat"
                    showTitle={true}
                    width={POPUP_width}
                    height={POPUP_height}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="lastname">
                        <RequiredRule />
                      </Item>
                      <Item dataField="firstname">
                        <RequiredRule />
                      </Item>
                      <Item dataField="cnp">
                        <RequiredRule />
                      </Item>
                      <Item dataField="position">
                        <RequiredRule />
                      </Item>
                      <Item dataField="employmentdate">
                        <RequiredRule />
                      </Item>
                      <Item>{ageInWork}</Item>
                      <Item dataField="netsalary">
                        <RequiredRule />
                      </Item>
                      <Item dataField="grosssalary">
                        <RequiredRule />
                      </Item>
                    </Item>
                    <Item
                      itemType="group"
                      caption="Observatii"
                      colCount={2}
                      colSpan={2}
                    >
                      <Item
                        dataField="details"
                        // editorType="dxTextArea"
                        colSpan={2}
                        editorOptions={{ height: 100 }}
                      />
                    </Item>
                  </Form>
                </Editing>
                <FilterRow visible={true} applyFilter="auto" />
                <Column dataField="details" visible={false} />
                <Column
                  dataField="grosssalary"
                  caption="Salariu Brut"
                  visible={false}
                />
                <Column
                  dataField="netsalary"
                  caption="Salariu Net"
                  visible={false}
                />
                <Column dataField="lastname" caption="Nume" />
                <Column dataField="firstname" caption="Prenume" />
                <Column dataField="cnp" caption="CNP" />
                <Column dataField="position" caption="Functie" />
                <Column
                  dataField="employmentdate"
                  caption="Data angajare"
                  dataType="datetime"
                />
                <Column
                  dataField="createdon"
                  caption="Data creare"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column
                  dataField="modifiedon"
                  caption="Data modificarii"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column type="buttons">
                  <Button name="edit" />
                  <Button name="delete" />
                </Column>

                <Toolbar>
                  <Item name="addRowButton" showText="always" />
                </Toolbar>
                 <Pager
                  allowedPageSizes={pageSizes}
                  showPageSizeSelector={false}
                />
                <Paging defaultPageSize={pageSizes[0]} /> 
                
              </DataGrid>
            </div>
          </React.Fragment>
        </>
      );

    if (this.state.TABLE_NAME === "client")
      return (
        <>
          <React.Fragment>
            <div className="gridContainer">
              <DataGrid
                id="gridContainer"
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={true}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                showRowLines={true}
                showColumnLines={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  confirmDelete={true}
                  useIcons={true}
                >
                  <Popup
                    title="Informatii client"
                    showTitle={true}
                    width={POPUP_width}
                    height={POPUP_height}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="lastname">
                        <RequiredRule />
                      </Item>
                      <Item dataField="firstname">
                        <RequiredRule />
                      </Item>
                      <Item dataField="cif">
                        <RequiredRule />
                      </Item>
                      <Item dataField="email">
                        <RequiredRule />
                      </Item>
                      <Item dataField="phoneno">
                        <RequiredRule />
                      </Item>
                    </Item>

                    <Item
                      itemType="group"
                      caption="Adresa"
                      colCount={2}
                      colSpan={2}
                    >
                      <Item
                        dataField="address"
                        // editorType="dxTextArea"
                        colSpan={2}
                        editorOptions={{ height: 100 }}
                      />
                    </Item>
                  </Form>
                </Editing>

                <FilterRow visible={true} applyFilter="auto" />
                <Column dataField="cif" caption="CIF" width={100} />
                <Column dataField="lastname" caption="Nume" />
                <Column dataField="firstname" caption="Prenume" />
                <Column dataField="email" caption="Adresa e-mail" />
                <Column dataField="phoneno" caption="Nr. telefon" />
                <Column dataField="address" caption="Adresa" />
                <Column
                  dataField="createdon"
                  caption="Data creare"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column
                  dataField="modifiedon"
                  caption="Data modificarii"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column type="buttons">
                  <Button name="edit" />
                  <Button name="delete" />
                  {/* <Button name='save' />
                            <Button name='cancel' /> */}
                </Column>

                <Toolbar>
                  <Item name="addRowButton" showText="always" />
                </Toolbar>
                <Pager
                  allowedPageSizes={pageSizes}
                  showPageSizeSelector={false}
                />
                <Paging defaultPageSize={10} />
              </DataGrid>
            </div>
          </React.Fragment>
        </>
      );

    if (this.state.TABLE_NAME === "car")
      return (
        <>
          <React.Fragment>
            <div className="gridContainer">
              <DataGrid
                id="gridContainer"
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={true}
                onEditorPreparing={this.onEditorPreparing}
                onEditorPrepared={this.onEditorPreparing}
                onEditingStart={this.onEditingStart}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                showRowLines={true}
                showColumnLines={true}
              >
                <Editing
                  key={this.key}
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  confirmDelete={true}
                  useIcons={true}
                >
                  <Popup
                    title="Informatii masina"
                    showTitle={true}
                    width={POPUP_width}
                    height={POPUP_height}
                  />
                  <Form onInitialized={this.onEditorPreparing2}>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="platenumber">
                        <RequiredRule />
                      </Item>
                      <Item dataField="vin">
                        <RequiredRule />
                      </Item>
                      <Item dataField="model">
                        <RequiredRule />
                      </Item>
                      <Item dataField="initialkm">
                        <RequiredRule />
                      </Item>
                      <Item dataField="year">
                        <RequiredRule />
                      </Item>
                      <Item dataField="leasing" >
                      </Item>
                      <Item dataField="leasingenddate" editorType="dxDateBox">
                      </Item>
                      <Item dataField="carid" visible={false} />
                    </Item>
                    <Item
                      itemType="group"
                      caption="Acte"
                      colCount={2}
                      colSpan={2}
                    >
                      {carDocumentGrid}
                      
                    </Item>
                  </Form>
                </Editing>
                <FilterRow visible={true} applyFilter="auto" />
                <Column dataField="carid" visible={false} caption="Carid" />
                <Column dataField="leasingenddate" caption="Data sfarsit leasing"  visible={false}/>
                <Column dataField="leasing" caption="Leasing"  visible={false}/>
                <Column dataField="platenumber" caption="Numar inmatriculare" />
                <Column dataField="vin" caption="VIN" />
                <Column dataField="model" caption="Model" />
                <Column dataField="initialkm" caption="Numar km" />
                <Column dataField="year" caption="An fabricatie" />
                <Column
                  dataField="createdon"
                  caption="Data creare"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column
                  dataField="modifiedon"
                  caption="Data modificarii"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column type="buttons">
                  <Button name="edit" />
                  <Button name="delete" />
                </Column>

                <Toolbar>
                  <Item name="addRowButton" showText="always" />
                </Toolbar>
                <Pager
                  allowedPageSizes={pageSizes}
                  showPageSizeSelector={false}
                />
                <Paging defaultPageSize={10} />
              </DataGrid>
            </div>
          </React.Fragment>
        </>
      );
      if (this.state.TABLE_NAME === "trail")
      return (
        <>
          <React.Fragment>
            <div className="gridContainer">
              <DataGrid
                id="gridContainer"
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={true}
                onEditorPreparing={this.onEditorPreparing}
                onEditorPrepared={this.onEditorPreparing}
                onEditingStart={this.onEditingStart}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                showRowLines={true}
                showColumnLines={true}
              >
                <Editing
                  key={this.key}
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  confirmDelete={true}
                  useIcons={true}
                >
                  <Popup
                    title="Informatii masina"
                    showTitle={true}
                    width={POPUP_width}
                    height={POPUP_height}
                  />
                  <Form onInitialized={this.onEditorPreparing2}>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="platenumber">
                        <RequiredRule />
                      </Item>
                      <Item dataField="vin">
                        <RequiredRule />
                      </Item>
                      <Item dataField="model">
                        <RequiredRule />
                      </Item>
                      <Item dataField="year">
                        <RequiredRule />
                      </Item>
                      <Item dataField="leasing">
                      </Item>
                      <Item dataField="leasingenddate" editorType="dxDateBox">
                      </Item>
                      <Item dataField="trailid" visible={false} />
                    </Item>
            
                  </Form>
                </Editing>
                <FilterRow visible={true} applyFilter="auto" />
                <Column dataField="trailid" visible={false} caption="trailid" />
                <Column dataField="leasingenddate" caption="Data sfarsit leasing"  visible={false}/>
                <Column dataField="leasing" caption="Leasing"  visible={false}/>
                <Column dataField="platenumber" caption="Numar inmatriculare" />
                <Column dataField="vin" caption="VIN" />
                <Column dataField="model" caption="Model" />
                <Column dataField="year" caption="An fabricatie" />
                <Column
                  dataField="createdon"
                  caption="Data creare"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column
                  dataField="modifiedon"
                  caption="Data modificarii"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column type="buttons">
                  <Button name="edit" />
                  <Button name="delete" />
                </Column>

                <Toolbar>
                  <Item name="addRowButton" showText="always" />
                </Toolbar>
                <Pager
                  allowedPageSizes={pageSizes}
                  showPageSizeSelector={false}
                />
                <Paging defaultPageSize={10} />
              </DataGrid>
            </div>
          </React.Fragment>
        </>
      );
    if (this.state.TABLE_NAME === "cardocument")
      return (
        <>
          <React.Fragment>
            <div className="">
              <DataGrid
                id="gridContainer"
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={true}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                showRowLines={true}
                showColumnLines={true}
                onEditingStart = {this.onEditingStart}
              >
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  confirmDelete={true}
                  useIcons={true}
                >
                  <Popup
                    title="Document"
                    showTitle={true}
                    width={POPUP_width}
                    height={POPUP_height}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="name">
                        <RequiredRule />
                      </Item>
                      <Item dataField="enddate" editorType="dxDateBox">
                        <RequiredRule />
                      </Item>
                    </Item>
                    <Item dataField="description" >
                      </Item>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <FileUpload />
                      {downloadFileButton}
                      {/* <FileUploader
                        selectButtonText="Select photo"
                        labelText=""
                        accept="image/*"
                        uploadMode="useForm"
                      /> */}
                    </Item>
                  </Form>
                </Editing>
                <FilterRow visible={true} applyFilter="auto" />

                <Column dataField="name" caption="Nume" />
                <Column
                  dataField="enddate"
                  caption="Data expirare"
                  dataType="date"
                  width={250}
                />

                <Column type="buttons">
                  <Button name="edit" />
                  <Button name="delete" />
                </Column>

                <Toolbar>
                  <Item name="addRowButton" showText="always" />
                </Toolbar>
                <Pager
                  allowedPageSizes={pageSizes}
                  showPageSizeSelector={false}
                />
                <Paging defaultPageSize={10} />
              </DataGrid>
            </div>
          </React.Fragment>
        </>
      );
    if (this.state.TABLE_NAME === "document")
      return (
        <>
          <React.Fragment>
            <div className="gridContainer">
              <DataGrid
                id="gridContainer"
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={true}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                showRowLines={true}
                showColumnLines={true}
                onEditingStart = {this.onEditingStart}
              >
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  confirmDelete={true}
                  useIcons={true}
                >
                  <Popup
                    title="Document"
                    showTitle={true}
                    width={POPUP_width}
                    height={POPUP_height}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="name">
                        <RequiredRule />
                      </Item>
                      <Item dataField="enddate" editorType="dxDateBox">
                        <RequiredRule />
                      </Item>
                    </Item>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <FileUpload />
                      {downloadFileButton}
                      {/* <FileUploader
                        selectButtonText="Select photo"
                        labelText=""
                        accept="image/*"
                        uploadMode="useForm"
                      /> */}
                    </Item>
                  </Form>
                </Editing>
                <FilterRow visible={true} applyFilter="auto" />

                <Column dataField="name" caption="Nume" />
                <Column
                  dataField="enddate"
                  caption="Data expirare"
                  dataType="date"
                  width={250}
                />

                <Column type="buttons">
                  <Button name="edit" />
                  <Button name="delete" />
                </Column>

                <Toolbar>
                  <Item name="addRowButton" showText="always" />
                </Toolbar>
                <Pager
                  allowedPageSizes={pageSizes}
                  showPageSizeSelector={false}
                />
                <Paging defaultPageSize={10} />
              </DataGrid>
            </div>
          </React.Fragment>
        </>
      );
    if (this.state.TABLE_NAME === "transportorder")
      return (
        <>
          <React.Fragment>
            <div className="gridContainer">
              <DataGrid
                id="gridContainer"
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={true}
                onEditorPreparing={this.onEditorPreparing}
                onEditorPrepared={this.onEditorPreparing}
                onEditingStart={this.onEditorPreparing}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                showRowLines={true}
                showColumnLines={true}
              >
                <Sorting mode="single" />
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  confirmDelete={true}
                  useIcons={true}
                >
                  <Popup
                    title="Ordin de transport"
                    showTitle={true}
                    width={POPUP_width}
                    height={POPUP_height}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="number" visible={false}>
                        <RequiredRule />
                      </Item>
                      <Item dataField="fromdate" dataType="date">
                        <RequiredRule />
                      </Item>
                      <Item >
                      </Item>
                      <Item dataField="total">
                        <RequiredRule />
                      </Item>
                      <Item dataField="currency">
                        <RequiredRule />
                      </Item>
                      <Item dataField="paymentdetails">
                        <RequiredRule />
                      </Item>
                      <Item dataField="supplierdata">
                        <RequiredRule />
                      </Item>
                      <Item dataField="requestedservice">
                        <RequiredRule />
                      </Item>
                      <Item dataField="km">
                        <RequiredRule />
                      </Item>
                      <Item dataField="mass">
                        <RequiredRule />
                      </Item>
                      <Item dataField="platenumber">
                        <RequiredRule />
                      </Item>
                      <Item dataField="noofroutes">
                        <RequiredRule />
                      </Item>
                    </Item>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      {generateReportButton}
                    </Item>
                  </Form>
                </Editing>
                <FilterRow visible={true} applyFilter="auto" />

                <Column dataField="number" caption="Numar" defaultSortIndex={0} defaultSortOrder="asc" />
                <Column
                  dataField="supplierdata"
                  caption="Date furnizor"
                  visible={false}
                />
                <Column
                  dataField="paymentdetails"
                  caption="Date plata"
                  visible={false}
                />
                <Column
                  dataField="requestedservice"
                  caption="Serviciu solicitat"
                  visible={false}
                />
                <Column dataField="km" caption="km" visible={false} dataType="numeric" />
                <Column
                  dataField="noofroutes"
                  caption="Nr curse"
                  visible={false}
                  dataType="numeric"
                />
                <Column
                dataField="platenumber"
                caption="Nr inmatriculare"
                visible={false}
              />
                <Column dataField="mass" caption="Masa" visible={false} dataType="numeric" />
                <Column dataField="fromdate" caption="Din data" dataType="date" />
                <Column dataField="total" caption="total" dataType="numeric" />
                <Column dataField="currency" caption="Moneda" />
                <Column dataField="createdon" caption="Data creare" dataType="datetime" />
                <Column dataField="modifiedon" caption="Data modificare" dataType="datetime" />
                <Column type="buttons">
                  <Button name="edit" />
                  <Button name="delete" />
                </Column>
                <Pager
                  allowedPageSizes={[5]}
                  showPageSizeSelector={false}
                />
                <Paging defaultPageSize={5} />
              </DataGrid>
            </div>
          </React.Fragment>
        </>
      );
    if (this.state.TABLE_NAME === "notification")
      return (
        <>
          <React.Fragment>
            <div className="gridContainer">
              <DataGrid
                id="gridContainer"
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={true}
                onEditorPreparing={this.onEditorPreparing}
                onEditorPrepared={this.onEditorPreparing}
                onEditingStart={this.onEditorPreparing}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                showRowLines={true}
                showColumnLines={true}

              >
                <Editing
                  mode="row"
                  allowUpdating={false}
                  allowAdding={false}
                  allowDeleting={true}
                  confirmDelete={true}
                  useIcons={true}
                ></Editing>

                <FilterRow visible={true} applyFilter="auto" />
                <Column dataField="description" caption="Descriere" />
                <Column
                  dataField="createdon"
                  caption="Data creare"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column
                  dataField="modifiedon"
                  caption="Data modificarii"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Pager
                  allowedPageSizes={pageSizes}
                  showPageSizeSelector={false}
                />
                <Paging defaultPageSize={10} />
              </DataGrid>
            </div>
          </React.Fragment>
        </>
      );
    if (this.state.TABLE_NAME === "profitability")


      return (
        <>

          <React.Fragment>

            <div className="gridContainer">
              <div className="addButtonContainer">
                <Button
                  width={120}
                  text="Adauga"
                  type="normal"
                  stylingMode="contained"
                  onClick={this.addProfitability}
                />
              </div>
              <DataGrid
                id="gridContainer"
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={true}
                onEditorPreparing={this.onEditorPreparing}
                onEditorPrepared={this.onEditorPreparing}
                onEditingStart={this.onEditorPreparing}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                showRowLines={true}
                showColumnLines={true}
                onRowDblClick={this.editProfitability}
              >
                <Editing
                  mode="row"
                  useIcons={true} />
                <FilterRow visible={true} applyFilter="auto" />
                <Column dataField="description" caption="Descriere" />
                <Column
                  dataField="createdon"
                  caption="Data creare"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Column
                  dataField="modifiedon"
                  caption="Data modificarii"
                  dataType="datetime"
                  allowEditing={false}
                />
                <Pager
                  allowedPageSizes={pageSizes}
                  showPageSizeSelector={false}
                />
                <Paging defaultPageSize={10} />
              </DataGrid>
            </div>
          </React.Fragment>
        </>
      );
  }
}

export default Grid;
