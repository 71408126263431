import React from 'react';
import NavBar from "./NavBar.js";
import Grid from './Grid.js';
import 'whatwg-fetch';


class Documents extends React.Component {
    render() {        
        return (
            <>
            <NavBar />
            <Grid  tableName = "document"/>
            <div className="Title">Acte</div>
            </>
        );
    }
}

export default Documents;
