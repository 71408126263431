import { useEffect, useState } from "react";
import * as React from "react";
// import "./Notifications.css";
import NavBar from "./NavBar.js";
import Grid from "./Grid.js";
// import { useNavigate } from "react-router-dom"

const Notifications = () => {
  // const navigate = useNavigate();
  // const [authenticated, setauthenticated] = useState(null);
  // useEffect(() => {
  //   const loggedInUser = localStorage.getItem("authenticated");
  //   if (loggedInUser) {
  //     setauthenticated(loggedInUser);
  //   }
  // }, []);

  // const URL_API = "http://localhost:5000";
  const URL_API = "https://mlktrans-yw61.onrender.com"
  
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      TableName: "car",
      RelatedKey: null,
      RelatedKeyTable: null
    })
  };
  fetch(URL_API + "/listItems", requestOptions)
    .then(response => response.json())
    .then(data => {
      window.carsData = data;
      localStorage.setItem("carsData", JSON.stringify(data));
    });


    const requestOptions2 = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        TableName: "trail",
        RelatedKey: null,
        RelatedKeyTable: null
      })
    };
    fetch(URL_API + "/listItems", requestOptions2)
      .then(response => response.json())
      .then(data => {
        window.trailsData = data;
        localStorage.setItem("trailsData", JSON.stringify(data));
      });
 
  return (
    <>
      <NavBar />
      <br/>
      <div className="Title">Notificari</div>
      <Grid tableName="notification" />
    </>
  );
};

export default Notifications;
