import * as React from "react";
import { useNavigate } from "react-router-dom";
import "./NavBar.css";
import "bootstrap/dist/css/bootstrap.min.css";

const NavBar = () => {
  const navigate = useNavigate();
  return (
    <div className="tab-wrapper">
      <div className="navMenu">
        <button className="btn tab-btn" onClick={() => navigate("/cars")}>
          Flota
        </button>
        <button className="btn tab-btn" onClick={() => navigate("/carsDetails")}>
          Detalii flota
        </button>
        <button className="btn tab-btn" onClick={() => navigate("/trails")}>
          Remorci
        </button>
        <button className="btn tab-btn" onClick={() => navigate("/trailsDetails")}>
          Detalii remorci
        </button>
        <button className="btn tab-btn" onClick={() => navigate("/clients")}>
          Clienti
        </button>
        <button className="btn tab-btn" onClick={() => navigate("/employees")}>
          Angajati
        </button>
        <button className="btn tab-btn" onClick={() => navigate("/documents")}>
          Acte
        </button>
        <button className="btn tab-btn"  id = "reportsSection"  onClick={() => navigate("/reports")}>
          Rapoarte
        </button>
        <button className="btn tab-btn" onClick={() => navigate("/Notifications")}>
          Notificari
        </button>
        <button  className="btn tab-btn" hidden="hidden" id = "profitabilitySection" onClick={() => navigate("/profitability")}>
          Profitability
        </button>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default NavBar;
