import React from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import DataGrid, {
  Column, Editing, Form, Pager, Popup,
  Paging, Scrolling, TotalItem, Summary
} from 'devextreme-react/data-grid';
import CustomStore from "devextreme/data/custom_store";
import 'devextreme/data/odata/store';
import NavBar from "./NavBar.js";

// const URL_API = "http://localhost:5000";
const URL_API = "https://mlktrans-yw61.onrender.com"
// const tabData = localStorage.carsData && JSON.parse(localStorage.carsData).recordsets ? JSON.parse(localStorage.carsData).recordsets[0] : [];
const POPUP_width = "80%";
const POPUP_height = "80%";
class CarsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { gridElements: [] };
    const tabData = localStorage.carsData && JSON.parse(localStorage.carsData).rows ? JSON.parse(localStorage.carsData).rows : [];
    console.log(tabData)
    for (let i = 0; i < tabData.length; i++) {
      this["itemGridRef" + i] = React.createRef();
      let TABLE_NAME = "cardetail";
      let RELATED_TABLE_NAME = "car";
      let gridData = new CustomStore({
        key: TABLE_NAME + "id",
        load: () =>
          this.sendRequest(URL_API + "/listItems", "POST", {
            TableName: TABLE_NAME,
            RelatedKey: tabData[i].carid,
            RelatedKeyTable: RELATED_TABLE_NAME,

          }),
        insert: (values) => {
          console.log(values)
          values.carId = tabData[i].carid;
          this.sendRequest(URL_API + "/insert", "POST", {
            TableName: TABLE_NAME,
            Values: JSON.stringify(values),
          })
        }
        ,
        update: (key, values) =>{
          let savedData = window.data ;
          this.sendRequest(URL_API + "/update", "POST", {
            id: key,
            TableName: TABLE_NAME,
            Values: JSON.stringify(values),
          })
          window.location.reload();
        },
        remove: (key) =>
          this.sendRequest(URL_API + "/delete", "POST", {
            TableName: TABLE_NAME,
            id: key,
          }),
      });
      let element = <Item title={tabData[i].platenumber + " " + tabData[i].model} key={"itemGrid" + i}>
        <DataGrid
          dataSource={gridData}
          allowInsert={true}
          keyExpr="ID"
          showBorders={true}
          id={"itemGrid" + i}
          ref={this["itemGridRef" + i]}
          columnWidth={100}
          onEditorPreparing={this.onEditorPreparing}
          onEditingStart={this.onEditingStart}
        >
          {/* <GroupPanel visible={true} />
          <Grouping autoExpandAll={true} /> */}
          <Pager
            allowedPageSizes={[10,20,30,40,50,60]}
            showPageSizeSelector={false}
          />
          <Paging defaultPageSize={5} />
          <Editing
            mode='popup'
            allowAdding={true}
            allowDeleting={true}
            allowUpdating={true}
            confirmDelete={false}
            useIcons={true}
          >
            <Popup title="Adauga inregistrare" showTitle={true} width={POPUP_width} height={POPUP_height} />

            <Form >
            <Item dataField="date" />
            <Item dataField="description" />
            <Item dataField="km" />
            <Item dataField="price" />
     
              <Item itemType="group" colCount={1} colSpan={2}>
                <Item dataField="carid" />
              </Item>

            </Form>
          </Editing>
          <Column dataField="carid" caption="Legatura" value={tabData[i].carid} visible={false} />
          <Column dataField="date" caption="Data" dataType="date"/>
          <Column dataField="km" caption="KM"   dataType="numeric"/>
          <Column dataField="price" caption="Cost"  dataType="numeric" width={150}/>
          <Column dataField="pescription" caption="Piese" width={1000} />
          
          <Column dataField="createdon" caption="Data creare" dataType="date" />

          <Scrolling columnRenderingMode="virtual" />
          <Summary recalculateWhileEditing={true}>
            <TotalItem
              column="km"
              summaryType="sum" />
            <TotalItem
              column="price"
              summaryType="sum" />
          </Summary>
        </DataGrid>
      </Item>
      this.state.gridElements.push({ element: element, index: i, data: tabData[i] });
    }
    this.priceGridRef = React.createRef();
    this.ratingGridRef = React.createRef();
  }
  onEditingStart(data){
    debugger
    window.data = data.data;
  }

  onEditorPreparing(data) {

    if (!data || !data.row || !data.row.isNewRow) {
      window.MODE = "EDIT";
      window.CURRENT_DATA = data;
    } else {
      window.MODE = "INSERT";
    }
  }

  sendRequest(url, method = "GET", data = {}) {
    if (method === "GET") {
      return fetch(url, {
        method,
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) return json.data;
          throw json.Message;
        })
      );
    }

    const params = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");
    return fetch(url, {
      method,
      body: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then(function (text) {
          let res = JSON.parse(text);
          return {
            data: res.recordset,
            totalCount: res.totalCount,
            summary: res.summary,
            groupCount: res.groupCount,
          };
        });
      }
      return result.json().then((json) => {
        throw json.Message;
      });
    });
  }

  render() {
    var tabLists = this.state.gridElements.map(x => x.element);
    return (

      <div>
        <NavBar />
        {/* <div className="Title">Profitabilitate</div> */}
        <div className='ContainerTabPanel'>
          <TabPanel id="tabPanel" deferRendering={false}>
            {tabLists}
          </TabPanel>
        </div>
      </div>
    );
  }

}

export default CarsDetails;
