import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Notifications from "./Notifications";
import Login from "./Login";
import Clients from "./Clients";
import Employees from "./Employees";
import Cars from "./Cars";
import Documents from "./Documents";
import Reports from "./Reports";
import Profitability from "./Profitability";
import "devextreme/dist/css/dx.light.css";
import CarsDetails from "./CarsDetails";
import Trail from "./Trail";
import TrailsDetails from "./TrailsDetails";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/notifications" element={<Notifications />} />
          <Route exact path="/clients" element={<Clients />} />
          <Route exact path="/employees" element={<Employees />} />
          <Route exact path="/cars" element={<Cars />} />
          <Route exact path="/carsDetails" element={<CarsDetails />} />
          <Route exact path="/trails" element={<Trail />} />
          <Route exact path="/trailsDetails" element={<TrailsDetails />} />
          <Route exact path="/documents" element={<Documents />} />
          <Route exact path="/reports" element={<Reports />} />
          <Route exact path="/profitability" element={<Profitability />} />

        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
