import React from "react";
import NavBar from "./NavBar.js";
import "whatwg-fetch";
import Grid from "./Grid.js";
// import Bell from "./Bell";

class Clients extends React.Component {
  render() {
    return (
      <>
        <NavBar />
        <Grid tableName="client" />
        <div className="Title">Clienti</div>
        {/* <Bell /> */}
      </>
    );
  }
}

export default Clients;
